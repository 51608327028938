import * as React from 'react'
import { Link } from 'gatsby'
import { graphql, useStaticQuery } from 'gatsby'
import { PrismicLink } from '@prismicio/react'
import { Container } from 'react-bootstrap'

import logo from "../images/logo.jpg"

export const Header = ({ isHomepage }) => {
	const targets = [{
		label: "Blogs",
		link: "/blogs"
	},
	{
		label : "ExploreSaas",
		link : "/products"

	},
	{
		label : "About",
		link : "/about"
	}]
	return (
		<Container>
			<div className={`site-header`}>
				<Link to="/">
					<div className="logo d-flex">
						<img style={{height: "40px"}} src={logo} alt="Saasternatives logo" />
						<div className='my-auto ms-3 mb-0'>
							<h5>Saasternatives</h5>
						</div>
					</div>
				</Link>
				<div className='d-none d-md-flex ms-auto my-auto '>
					{targets.map((navItem, index) => {
						return (
							<div className='nav-item me-4' key={`link-${index}`}>
								<Link to={navItem.link}>
									{navItem.label}
								</Link>
							</div>
						)
					})}
				</div>

			</div>
		</Container>
	)
}
