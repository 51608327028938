import { Link } from 'gatsby'
import * as React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import logo from "../images/logo.svg"

export const Footer = () => (
	<div className='footer'>
		<Container>
			<div className="logo d-flex">
				<div className='d-flex'>
					<img style={{ height: "40px" }} src={logo} alt="Saasternatives logo" />
					<div className='my-auto ms-3 mb-0'>
						<h5>Saasternatives</h5>
					</div>
				</div>

			</div>
			<p className='mt-4'>
				&copy; Soorkie Media
			</p>
			<Row>
				<Col md={6}>
					<p>
						Saasternatives is an independent blog about the latest and most usefull Saas tools availabe.
						All the contents are written by us and are our opinion. We do not accept any pre written content from
						marketing teams. We strive for the best content quality.
					</p>
					<p>
						We monetize when companies want to promote their post which is completely written by us independently. 
						Providing trustworthy content to anyone on the internet without any paywall is our objective.
					</p>
				</Col>
				<Col md={6}>
					<Link to="/blogs">
						All Blogs
					</Link>
				</Col>
			</Row>
		</Container>

	</div>
)
