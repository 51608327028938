import * as React from 'react'

import { Header } from './Header'
import { Footer } from './Footer'
import './../styles/reset.css'
import './../styles/common.css'
import './../styles/style.css'
import { Container } from 'react-bootstrap'

export const Layout = ({ isHomepage, children, navigation }) => (
  <>    
  <Header isHomepage={isHomepage} navigation={navigation} />
    <div style={{ minHeight: "100vh" }}>
      {children}
    </div>
    <Footer />
  </>

)
